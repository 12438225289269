<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">检验数量统计</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board :config="config" style="height:14.28rem;font-size:1rem;color:#ffc103" />
      </div>
    </div>
  </div>
</template>

<script>
import {getBigOrderQaList10} from '../libs/axios';
export default {
  data() {
    return {
      config: {
        header: ["日期", "检验数", "合格数", "返工数", "返工率"],
        data:[],
        rowNum: 20, //表格行数
        headerHeight: 65,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [80,280,180,180,180,180],
        align: ["center"]
      }
    };
  },
  components: {},
  mounted() {
    this.init();
     setInterval(this.init,120000);
  },
  methods: {
    init() {
      this.getWageList();
    },
     getWageList() {
      this.loading = true;
      var orderId=this.$store.state.orderId;
      getBigOrderQaList10(4,orderId).then((res) => {
        // vm.$store.commit("setMessageType", res.result);
          // alert(JSON.stringify(res.data));
          
          this.loading = false;
          this.$set(this.config,  this.config.data,res.data);
          this.config.data=res.data;
      });
    },
  }
};
</script>

<style lang="scss">
#centreRight1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  font-size: 1.2rem;
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
    font-size:0.5rem;
  }
  .text {
    font-size: 0.5rem;
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
    font-size: 0.7rem;
  }
  .header-item{
    font-size: 0.5rem;
  }
    .row-item{
    font-size: 0.5rem;
    color:"#99cc33"
  }
  .ceil{
    color: rgb(154, 205, 50);
  }
}
</style>